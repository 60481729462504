<script>
    import {onMount} from "svelte";
    import api from "./api";
    import Document from "./Document.svelte";

    let fileSelect;
    let uploadQueue = [];
    let history = null;
    let working = false;
    let uploadType = "default";

    onMount(async () => {
        history = await api.documents(false);
    });

    function browse(uType) {
        uploadType = uType || "default";
        console.log(uploadType);
        fileSelect.click();
    }

    const allowedTypes = new Set(["application/pdf", "image/jpeg"]);
    let fileCounter = 0;

    function addToUploadQueue() {
        for (const file of fileSelect.files) {
            const exists = uploadQueue.some(f => f.file.name === file.name && f.file.type === file.type && f.file.size === file.size && f.file.lastModified === file.lastModified);
            if (!exists) {
                let error = null;
                if (file.size > 26214400) {
                    error = "File is larger than 25MB";
                } else if (!allowedTypes.has(file.type)) {
                    error = "File type not supported";
                }
                uploadQueue.push({
                    id: fileCounter++,
                    file: file,
                    error: error,
                    status: error || "Ready to upload",
                    name: uploadType === "default" ? null : uploadType
                });
                uploadQueue = uploadQueue;
            }
        }
    }

    function removeFromUploadQueue(index) {
        uploadQueue.splice(index, 1);
        uploadQueue = uploadQueue;
    }

    async function upload() {
        working = true;
        for (let i = 0; i < uploadQueue.length; i++) {
            const file = uploadQueue[i];
            if (file.error) {
                uploadQueue[i].status = `Skipped: ${file.error}`;
                continue;
            }
            const result = await api.upload(file.file, file.name, e => {
                if (e.type === "loadstart") {
                    uploadQueue[i].status = `Uploading...`;
                } else if (e.type === "progress") {
                    uploadQueue[i].status = `Uploading (${(
                            (e.loaded / e.total) *
                            100.0
                    ).toLocaleString("en-GB", {maximumFractionDigits: 1})}%)`;
                } else if (e.type === "load") {
                    uploadQueue[i].status = "Processing...";
                } else if (e.type === "error") {
                    uploadQueue[i].status = "Failed";
                }
            });
            if (result.doc) {
                uploadQueue[i].doc = result.doc;
            }
            uploadQueue[i].status = result.success ? "Done!" : "Failed";
        }
        const completed = uploadQueue.filter(f => f.doc);
        const failed = uploadQueue.filter(f => !f.doc);
        for (const result of completed) {
            history.unshift(result.doc);
        }
        history = history;
        uploadQueue = failed;
        working = false;
    }
</script>

<div class="container">
    <h1>Upload Documents</h1>
    <p>
        If you've been asked to send us documents, you can do so by tapping the
        button below. We can accept photos or PDF documents up to 25MB in size,
        and you can upload multiple files at a time.
    </p>
    <div class="documents">
        {#each uploadQueue as doc, index (`${doc.fileName}_${index}`)}
            <Document doc={doc} closable on:remove={() => removeFromUploadQueue(index)}>
                <h3>{doc.file.name}</h3>
                <p>{doc.status}</p>
            </Document>
        {/each}
    </div>
    <div class="upload-buttons">
        <input bind:this={fileSelect} on:change={addToUploadQueue} type="file" id="fileSelect" multiple/>
        {#if uploadQueue.length}
            <button class="start-upload" on:click={upload} disabled={working}>Start Uploading</button>
        {/if}
        <button on:click={() => browse("Proof of Income")} disabled={working}>Upload Proof of Income..</button>
        <button on:click={() => browse()} disabled={working}>Upload Other Documents..</button>
    </div>
    <h2>History</h2>
    {#if !history}
        <p>Loading...</p>
    {:else}
        <div class="documents">
            {#if history.length}
                {#each history as doc, index (`${doc.fileName}_${index}`)}
                    <Document doc={doc}/>
                {/each}
            {:else}
                <p>We haven't received any documents from you yet.</p>
            {/if}
        </div>
    {/if}
</div>

<style>
.documents {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    margin-bottom: 1em;
}

input[type=file] {
    display: none;
}

.start-upload {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#8ed14b), to(#669635));
    background-image: linear-gradient(to bottom, #8ed14b 0%, #669635 100%);
}

.start-upload:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#adff5c), to(#8acc47));
    background-image: linear-gradient(to bottom, #adff5c 0%, #8acc47 100%);
}

@media screen and (max-width: 1236px) {
    .documents {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 767px) {
    .documents {
        grid-template-columns: 1fr;
    }

    .upload-buttons button {
        display: block;
        width: 100%;
        margin-bottom: 0.5em;
    }

    .upload-buttons button:last-of-type {
        margin-bottom: 0;
    }
}</style>